<template>
    <div class="mb-2">
        <a
            v-if="type !== 'router'"
            :href="href"
            :target="target"
            class="py-2 pl-2 text-nav cursor-pointer text-gray-700 font-bold rounded-lg block hover:text-sbr-purple hover:bg-sbr-light-purple"
            @click="$emit('click')"
        >
            <slot></slot>
        </a>
        <router-link
            v-if="type === 'router'"
            :to="href"
            class="py-2 pl-2 text-nav cursor-pointer text-gray-700 font-bold rounded-lg block hover:text-sbr-purple hover:bg-sbr-light-purple"
            active-class="text-sbr-light-purple bg-sbr-purple"
            :exact="exact"
            @click.native="$emit('click')"
        >
            <slot></slot>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: 'navigation-link',
        props: {
            href: String,
            title: String,
            target: String,
            type: String,
            tag: String,
            exact: Boolean,
        },
    }
</script>
