<template>
    <div class="m-5">
        <div class="">
            <div class="my-6 rounded-lg border-2 border-gray-200 shadow-sm">
                <table class="text-left w-full">
                    <thead>
                        <slot name="header"></slot>
                    </thead>
                    <tbody>
                        <slot name="body"></slot>
                    </tbody>
                    <tfoot>
                        <slot name="footer"></slot>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'table-table',
        props: {
        },
    };
</script>
