<template>
   <div class="flex h-screen justify-center">
    <div class="w-full max-w-sm m-auto">
      <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div class="mb-8"><img width="320" height="88" src="/img/SBR_horiz_med.png" /></div>

        <p v-if="is_loading">Checking your beta access link...</p>
        <p class="" v-if="is_valid">Your email has been verified! <a href="/" class="underline">Access</a> your account now to change your password.</p>
        <p class="text-red-500 italic mb-4" v-if="not_valid">The verification email link you have used is not valid.</p>
        <p class="text-red-500 italic mb-4" v-if="expired">The verification email link you have used has expired. Contact an admin to have a new email resent.</p>
      </div>

      <p class="text-center text-gray-500 text-xs">
        &copy;2022 All rights reserved.
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Verify',
    created() {
        this.verify();
    },
    data() {
      return {
        key: this.$route.query.key || '',
        is_loading: true,
        is_valid: false,
        not_valid: false,
        expired: false,
      }
    },
    methods: {
      verify: function () {
          this.$http.post('/api/v1/auth/verify', {key: this.key}).then(response => {
              this.is_loading = false;

              if (response.data.status === 'valid') {
                this.is_valid = true;

                let token = response.data.access_token;
                let user = response.data.user;

                this.$store.dispatch('verified', { token, user }).then(() => {

                })
                .catch(err => {

                })
              } else if(response.data.status === 'expired') {
                this.expired = true;
              } else {
                this.not_valid = true;
              }
          }).catch(error => {
              this.is_loading = false;
              this.not_valid = true;
          });
      }
    }
  }
</script>
