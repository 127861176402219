<template>
    <div class="col-span-6" :class="{'sm:col-span-6': wide, 'sm:col-span-3': !wide}">
        <slot></slot>
    </div>
</template>

<script>

    export default {
        name: 'form-field',
        props: {
            wide: Boolean,
        },
    };
</script>
