<template>
    <label class="tracking-wide text-gray-700 text-sm font-bold mb-2" :for="id">{{ label }}</label>
</template>

<script>
    export default {
        name: 'form-label',
        props: {
            id: String,
            label: String,
        },
    }
</script>
