require('./bootstrap');

import { createApp } from 'vue';
import Notifications from '@kyvg/vue3-notification';
import Axios from 'axios';
import moment from 'moment-timezone';
import Paginate from 'vuejs-paginate-next';
import Multiselect from 'vue-multiselect';
import Chartkick from 'vue-chartkick';
import VueCookies from 'vue-cookies';
import Chart from 'chart.js';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

import Main from './components/Main';
import router from './routes';
import store from './store';
import { capitalize, formatDate } from "@/filters/strings";

moment.tz.setDefault('UTC');

Bugsnag.start({
    apiKey: process.env.MIX_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
});

const app = createApp(Main);
app.use(Bugsnag.getPlugin('vue'));
//app.use(VModal, { dynamic: true, dynamicDefaults: { clickToClose: false } })
app.use(Notifications);
app.use(Chartkick.use(Chart));
app.use(router);
app.use(store);
app.component('paginate', Paginate);
app.component('multiselect', Multiselect);

// Axios/Auth Setup
app.config.globalProperties.$http = Axios;
app.config.globalProperties.$http.defaults.headers.common['Content-Type'] = 'application/json';
app.config.globalProperties.$http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
app.config.globalProperties.$http.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

if(window.$cookies.get('token')) {
    app.config.globalProperties.$http.defaults.headers.common['Authorization'] = 'Bearer '+window.$cookies.get('token');
}

// Filters
app.config.globalProperties.$filters = {
    capitalize: capitalize,
    formatDate: formatDate,
};

app.mount('#app');

