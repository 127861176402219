<template>
    <a href="javascript:void(0);" @click="$emit('click')" class="mx-2 text-black hover:text-sbr-orange">
        <i class="fas fa-trash-alt"></i> <slot></slot>
    </a>
</template>

<script>

    export default {
        name: 'table-delete-link',
        emits: [
            'click',
        ],
        props: {
        },
    };
</script>
