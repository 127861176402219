<template>
    <td class="py-4 px-4 text-gray-700" v-bind:class="{'text-right': last, 'hidden md:table-cell': hideSm, 'font-bold': bold}">
        <slot></slot>
    </td>
</template>

<script>

    export default {
        name: 'table-cell',
        props: {
            last: Boolean,
            hideSm: Boolean,
            bold: Boolean,
        },
    };
</script>
