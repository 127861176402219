<template>
    <div>
        <form-label :id="id" :label="label"></form-label>
        <input
            class="appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-full shadow-sm py-2 px-4 mb-3 focus:outline-none focus:bg-white focus:border-sbr-purple focus:ring-sbr-purple"
            :id="id"
            :name="name"
            :readonly="readonly"
            :disabled="disabled"
            :type="type"
            :placeholder="placeholder"
            v-model="input_value"
            @keyup.enter="$emit('keyupenter', $event.target.value)"
        >
    </div>
</template>

<script>
    import FormLabel from './Label'

    export default {
        name: 'form-input',
        components: {
            FormLabel,
        },
        props: {
            id: String,
            name: String,
            label: String,
            required: Boolean,
            readonly: Boolean,
            disabled: Boolean,
            placeholder: String,
            type: {
                type: String,
                default: 'text',
            },
            modelValue: {
                type: [String, Number],
            },
        },
        emits: [
            'update:modelValue',
            'keyupenter',
        ],
        computed: {
            input_value: {
                get() {
                    return this.modelValue;
                },
                set(val) {
                    this.$emit('update:modelValue', val);
                }
            },
        },
    }
</script>
