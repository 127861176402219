<template>
    <div>
        <layout-header title="SeeBotTalk Plans"></layout-header>


    </div>
</template>

<script>
    import axios from 'axios'
    import LayoutApp from '../../layouts/App'
    import LayoutHeader from '../../shared/layout/Header'

    export default {
        name: 'PlansTalk',
        components: {
        },
        created() {
            this.$emit('update:layout', LayoutApp);
            this.fetch();
        },
        data() {
            return {
                plans: [],
                is_owner: (this.$store.getters.user.account_level === 'owner' ? true : false),
            }
        },
        computed: {
            user: function() {
                return this.$store.getters.user;
            }
        },
        methods: {
            fetch(page) {
                axios.get(this.url(page)).then(this.refresh);
            },
            url(page) {
                if (!page) {
                    page = 1;
                }
                return `/api/v1/plans/talk`;
            },
            refresh({data}) {
                this.plans = data.data;
                window.scrollTo(0,0);
            },
        }
    }
</script>
