<template>
    <div>
        <layout-header title="Edit Account"></layout-header>

        <form-section title="Account Details">
            <form-field>
                <form-input id="name" name="name" label="Name" v-model="account.name"></form-input>
                <p class="text-red-500 text-xs italic" v-if="errors && errors.name">{{ errors.name[0] }}</p>
            </form-field>
        </form-section>

        <form-action>
            <form-button @click="save">
                Update Account
            </form-button>

            <form-button secondary @click="cancel">
                Cancel
            </form-button>
        </form-action>
    </div>
</template>

<script>
import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App';
    import LayoutHeader from '../../shared/layout/Header';
    import FormSection from '../../shared/form/Section';
    import FormAction from '../../shared/form/Action';
    import FormField from '../../shared/form/Field';
    import FormInput from '../../shared/form/Input';
    import FormButton from '../../shared/form/Button';
    import ValidationNotice from '../../shared/form/ValidationNotice';

    export default {
        name: 'EditAccount',
        components: {
            LayoutApp,
            LayoutHeader,
            FormAction,
            FormSection,
            FormField,
            FormInput,
            FormButton,
            ValidationNotice,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));

            this.fetchAccount();
        },
        data() {
            return {
                account: {
                    name: '',
                },
                errors: []
            }
        },
        methods: {
            fetchAccount() {
                this.$http.get(`/api/v1/account`).then(this.refreshAccount);
            },
            refreshAccount({data}) {
                this.account = data.data;
            },
            save() {
                this.$http.put('/api/v1/account', this.account).then(response => {
                    this.$notify({
                        group: 'alerts',
                        type: 'success',
                        title: 'Your account has been updated',
                        duration: 5000,
                        speed: 1000
                    });
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {};
                    }
                });
            },
            cancel() {
                this.$router.back();
            },
        }
    }
</script>
