<template>
    <div>
        <a
            v-if="type !== 'router'"
            :href="href"
            :target="target"
            class="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-400"
            @click="$emit('click')"
        >
            <slot></slot>
        </a>
        <router-link
            v-if="type === 'router'"
            :to="href"
            class="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-400"
            @click.native="$emit('click')"
        >
            <slot></slot>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: 'header-dropdown-link',
        props: {
            href: String,
            title: String,
            target: String,
            type: String,
        },
    }
</script>
