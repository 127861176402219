<template>
    <router-link :to="href" class="mx-2 text-black hover:text-sbr-orange">
        <i class="fas fa-pencil-alt"></i> <slot></slot>
    </router-link>
</template>

<script>

    export default {
        name: 'table-edit-link',
        props: {
            href: [String, Object],
        },
    };
</script>
