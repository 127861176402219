<template>
    <div>
        <layout-header title="Edit User"></layout-header>

        <form-section title="User Details">
            <form-field>
                <form-input id="first_name" name="first_name" label="First Name" v-model="user.first_name"></form-input>
                <form-validation param="first_name" :errors="errors" />
            </form-field>

            <form-field>
                <form-input id="last_name" name="last_name" label="Last Name" v-model="user.last_name"></form-input>
                <form-validation param="last_name" :errors="errors" />
            </form-field>

            <form-field>
                <form-input id="email" name="email" label="Email Address" v-model="user.email"></form-input>
                <form-validation param="email" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="status" name="status" label="Status" v-model="user.status">
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                </form-select>
                <form-validation param="status" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="client_id" name="client_id" label="Client" v-model="user.client_id">
                    <option value="">- Select Client -</option>
                    <option v-for="client in clients" v-bind:key="client.id" :value="client.id">{{ client.name }}</option>
                </form-select>
                <form-validation param="client_id" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="account_level" name="account_level" label="Account Level" v-model="user.account_level" :disabled="user.account_level === 'owner' && current_user.is_admin === 0">
                    <option value="owner">Owner</option>
                    <option value="admin">Admin</option>
                    <option value="user">User</option>
                </form-select>
                <form-validation param="account_level" :errors="errors" />
            </form-field>

            <form-field>
                <form-input id="password" name="password" label="Password" type="password" v-model="user.password"></form-input>
                <form-validation param="password" :errors="errors" />
            </form-field>

            <form-field>
                <form-input id="confirm_password" name="confirm_password" label="Confirm Password" type="password" v-model="user.confirm_password"></form-input>
                <form-validation param="confirm_password" :errors="errors" />
            </form-field>

            <form-field v-if="current_user.is_admin">
                <form-checklist label="Send Access Email" mdcols="2">
                    <form-checkbox label="Standard Welcome Email" id="send_welcome" v-model="user.send_welcome" />
                    <form-checkbox label="Beta Welcome Email" id="send_beta_welcome" v-model="user.send_beta_welcome" />
                </form-checklist>
            </form-field>
        </form-section>

        <form-action>
            <form-button @click="save">
                Update User
            </form-button>

            <form-button secondary @click="cancel">
                Cancel
            </form-button>
        </form-action>
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App';
    import LayoutHeader from '../../shared/layout/Header';
    import FormSection from '../../shared/form/Section';
    import FormAction from '../../shared/form/Action';
    import FormField from '../../shared/form/Field';
    import FormInput from '../../shared/form/Input';
    import FormSelect from '../../shared/form/Select';
    import FormChecklist from '../../shared/form/Checklist';
    import FormCheckbox from '../../shared/form/Checkbox';
    import FormButton from '../../shared/form/Button';
    import FormValidation from '../../shared/form/ValidationNotice';

    export default {
        name: 'EditUser',
        components: {
            LayoutApp,
            LayoutHeader,
            FormAction,
            FormSection,
            FormField,
            FormInput,
            FormSelect,
            FormChecklist,
            FormCheckbox,
            FormButton,
            FormValidation,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));

            this.fetchUser();
            this.fetchClients();
        },
        computed: {
            id () {
                return this.$route.params.id;
            },
            current_user: function() {
                return this.$store.getters.user;
            }
        },
        data() {
            return {
                clients: [],
                user: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    password: '',
                    confirm_password: '',
                    client_id: '',
                    status: 'active',
                    account_level: 'user',
                    send_welcome: false,
                    send_beta_welcome: false,
                },
                errors: []
            }
        },
        methods: {
            fetchClients() {
                this.$http.get(`/api/v1/admin/clients`).then(this.refreshClients);
            },
            refreshClients({ data }) {
                this.clients = data.data;
            },
            fetchUser() {
                this.$http.get(`/api/v1/admin/users/${this.id}`).then(this.refreshUser);
            },
            refreshUser({data}) {
                this.user = data.data;
            },
            save() {
                this.$http.put('/api/v1/admin/users/'+this.id, this.user).then(response => {
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The user has been updated',
                    duration: 5000,
                    speed: 1000
                });

                this.$router.push({name: 'list_users'});
              }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors || {};
                }
              });
            },
            cancel() {
                this.$router.back();
            },
        }
    }
</script>
