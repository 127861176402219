<template>
    <span v-bind:class="{'bg-green-200 text-green-600': (status === 'active'), 'bg-gray-400 text-gray-700': (status === 'inactive')}" class="font-bold py-1 px-2 rounded-full text-sm">{{ $filters.capitalize(status) }}</span>
</template>

<script>

    export default {
        name: 'table-status-badge',
        props: {
            status: String,
        },
    };
</script>
