<template>
    <div>
        <layout-header title="Edit Client"></layout-header>

        <form-section title="Client Details">
            <form-field>
                <form-input id="name" name="name" label="Name" v-model="client.name"></form-input>
                <form-validation param="name" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="status" name="status" label="Status" v-model="client.status">
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                </form-select>
                <form-validation param="status" :errors="errors" />
            </form-field>

            <form-field>
                <form-checklist label="Permissions" mdcols="3">
                    <form-checkbox label="Chat" id="permission_chat" v-model="client.permissions.chat" />
                    <form-checkbox label="Links" id="permission_links" v-model="client.permissions.links" />
                    <form-checkbox label="Text" id="permission_text" v-model="client.permissions.text" />
                    <form-checkbox label="Talk" id="permission_talk" v-model="client.permissions.talk" />
                    <form-checkbox label="Lookup" id="permission_lookup" v-model="client.permissions.lookup" />
                    <form-checkbox label="Connect" id="permission_connect" v-model="client.permissions.connect" />
                </form-checklist>
                <form-validation param="permissions" :errors="errors" />
            </form-field>
        </form-section>

        <transition name="fade">
            <form-section title="Link Settings" v-if="client.permissions.links && current_user.is_admin">
                <form-field>
                    <form-input id="rate_limit_per_minute" name="rate_limit_per_minute" label="Rate Limit (per minute)" v-model="client.settings.links.rate_limit_per_minute"></form-input>
                    <form-validation param="settings.links.rate_limit_per_minute" :errors="errors" />
                </form-field>

                <form-field>
                    <form-input id="rate_limit_per_day" name="rate_limit_per_day" label="Rate Limit (per day)" v-model="client.settings.links.rate_limit_per_day"></form-input>
                    <form-validation param="settings.links.rate_limit_per_day" :errors="errors" />
                </form-field>

                <form-field>
                    <form-input id="maximum_links" name="maximum_links" label="Maximum Links" v-model="client.settings.links.maximum_links"></form-input>
                    <form-validation param="settings.links.maximum_links" :errors="errors" />
                </form-field>

                <form-field>
                    <form-input id="maximum_custom_domains" name="maximum_custom_domains" label="Maximum Custom Domains" v-model="client.settings.links.maximum_custom_domains"></form-input>
                    <form-validation param="settings.links.maximum_custom_domains" :errors="errors" />
                </form-field>

                <form-field>
                    <form-checklist label="Available System Domains">
                        <div v-for="domain in domains" v-bind:key="domain.id" class="">
                            <form-checkbox :label="domain.name" :id="'domain_'+domain.id" v-model="client.settings.links.domain_ids" :value="domain.id" />
                        </div>
                    </form-checklist>
                    <form-validation param="settings.links.domain_ids" :errors="errors" />
                </form-field>

                <form-field>
                    <form-checklist label="Link Features">
                        <form-checkbox label="Reports" id="links_feature_reports" v-model="client.settings.links.features.reports" />
                        <form-checkbox label="QR Codes" id="links_feature_qr_codes" v-model="client.settings.links.features.qr_codes" />
                        <form-checkbox label="Link Type (Predefined)" id="links_feature_link_type_predefined" v-model="client.settings.links.features.link_type_predefined" />
                        <form-checkbox label="Link Type (Trackable)" id="links_feature_link_type_trackable" v-model="client.settings.links.features.link_type_trackable" />
                        <form-checkbox label="Conditional Redirects" id="links_feature_conditional_redirects" v-model="client.settings.links.features.conditional_redirects" />
                        <form-checkbox label="App Deep Linking" id="links_feature_app_deep_linking" v-model="client.settings.links.features.app_deep_linking" />
                        <form-checkbox label="Tracking Scripts" id="links_feature_tracking_scripts" v-model="client.settings.links.features.tracking_scripts" />
                        <form-checkbox label="Link Expiration" id="links_feature_link_expiration" v-model="client.settings.links.features.link_expiration" />
                        <form-checkbox label="Custom OG Tags" id="links_feature_custom_og_tags" v-model="client.settings.links.features.custom_og_tags" />
                    </form-checklist>
                    <form-validation param="settings.links.features" :errors="errors" />
                </form-field>
            </form-section>
        </transition>

        <transition name="fade">
            <form-section title="Talk Settings" v-if="client.permissions.talk && current_user.is_admin">
                <form-field>
                    <form-input id="maximum_numbers" name="maximum_numbers" label="Maximum Numbers" v-model="client.settings.talk.maximum_numbers"></form-input>
                    <form-validation param="settings.talk.maximum_numbers" :errors="errors" />
                </form-field>

                <form-field>
                    <form-input id="maximum_sequences" name="maximum_sequences" label="Maximum Sequences" v-model="client.settings.talk.maximum_sequences"></form-input>
                    <form-validation param="settings.talk.maximum_sequences" :errors="errors" />
                </form-field>

                <form-field>
                    <form-checklist label="Talk Features">
                        <form-checkbox label="Reports" id="talk_feature_reports" v-model="client.settings.talk.features.reports" :value="true" />
                        <form-checkbox label="Speech to Text" id="talk_feature_speech_to_text" v-model="client.settings.talk.features.speech_to_text" :value="true" />
                        <form-checkbox label="Record" id="talk_feature_record" v-model="client.settings.talk.features.record" :value="true" />
                        <form-checkbox label="Transcribe" id="talk_feature_transcribe" v-model="client.settings.talk.features.transcribe" :value="true" />
                        <form-checkbox label="Dial Lists (default only)" id="talk_feature_dial_lists" v-model="client.settings.talk.features.dial_lists" :value="true" />
                        <form-checkbox label="Dial Lists (custom)" id="talk_feature_dial_lists_manage" v-model="client.settings.talk.features.dial_lists_manage" :value="true" />
                    </form-checklist>
                    <form-validation param="settings.talk.features" :errors="errors" />
                </form-field>
            </form-section>
        </transition>

        <transition name="fade">
            <form-section title="Lookup Settings" v-if="client.permissions.lookup && current_user.is_admin">
                <form-field>
                    <form-checklist label="Lookup Features">
                        <form-checkbox label="Federal Reps" id="lookup_feature_rep_lookup_federal" v-model="client.settings.lookup.features.rep_lookup_federal" :value="true" />
                        <form-checkbox label="State Reps" id="lookup_feature_rep_lookup_state" v-model="client.settings.lookup.features.rep_lookup_state" :value="true" />
                    </form-checklist>
                    <form-validation param="settings.lookup.features" :errors="errors" />
                </form-field>
            </form-section>
        </transition>

        <transition name="fade">
            <form-section title="Connect Settings" v-if="client.permissions.connect && current_user.is_admin">
                <form-field>
                    <form-checklist label="Connect Services">
                        <form-checkbox label="ActBlue" id="connect_service_actblue" v-model="client.settings.connect.services.actblue" :value="true" />
                        <form-checkbox label="Action Network" id="connect_service_actionnetwork" v-model="client.settings.connect.services.actionnetwork" :value="true" />
                    </form-checklist>
                    <form-validation param="settings.connect.services" :errors="errors" />
                </form-field>
            </form-section>
        </transition>

        <transition name="fade">
            <form-section title="Service Settings" v-if="current_user.is_admin">
                <form-field>
                    <form-select id="services_twilio_status" name="services_twilio_status" label="Twilio" v-model="client.settings.services.twilio.status">
                        <option v-if="client.settings?.services?.twilio?.account_sid === null" :value="null">Not Created</option>
                        <option v-if="client.settings?.services?.twilio?.account_sid === null" :value="'active'">Create</option>
                        <option v-if="client.settings?.services?.twilio?.account_sid !== null" :value="'active'">Active</option>
                        <option v-if="client.settings?.services?.twilio?.account_sid !== null" :value="'suspended'">Suspend</option>
                        <option v-if="client.settings?.services?.twilio?.account_sid !== null" :value="'closed'">Close</option>
                    </form-select>
                    <form-validation param="settings.services?.twilio" :errors="errors" />
                </form-field>

                <form-field v-if="client.settings.services?.twilio.account_sid !== null">
                    <form-input id="services_twilio_account_sid" name="services_twilio_account_sid" label="Twilio SID" v-model="client.settings.services.twilio.account_sid" readonly></form-input>
                </form-field>
            </form-section>
        </transition>

        <form-action>
            <form-button @click="save">
                Update Client
            </form-button>

            <form-button secondary @click="cancel">
                Cancel
            </form-button>
        </form-action>
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App';
    import LayoutHeader from '../../shared/layout/Header';
    import FormSection from '../../shared/form/Section';
    import FormAction from '../../shared/form/Action';
    import FormField from '../../shared/form/Field';
    import FormInput from '../../shared/form/Input';
    import FormSelect from '../../shared/form/Select';
    import FormChecklist from '../../shared/form/Checklist';
    import FormCheckbox from '../../shared/form/Checkbox';
    import FormButton from '../../shared/form/Button';
    import FormValidation from '../../shared/form/ValidationNotice';

    export default {
        name: 'EditClient',
        components: {
            LayoutApp,
            LayoutHeader,
            FormAction,
            FormSection,
            FormField,
            FormInput,
            FormSelect,
            FormChecklist,
            FormCheckbox,
            FormButton,
            FormValidation,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));

            this.fetchClient();
            this.fetchDomains();
        },
        computed: {
            id: function() {
              return this.$route.params.id;
            },
            current_user: function() {
              return this.$store.getters.user;
            }
        },
        data() {
            return {
                client: {
                    id: '',
                    name: '',
                    status: 'active',
                    permissions: {
                        chat: false,
                        links: false,
                        text: false,
                        talk: false,
                        lookup: false,
                    },
                    settings: {
                        links: {
                            domain_ids: [],
                            rate_limit_per_minute: 0,
                            rate_limit_per_day: 0,
                            maximum_links: 100,
                            maximum_custom_domains: 0,
                            features: {
                                reports: true,
                                qr_codes: false,
                                link_type_predefined: false,
                                link_type_trackable: false,
                                conditional_redirects: false,
                                app_deep_linking: false,
                                tracking_scripts: false,
                                link_expiration: false,
                                custom_og_tags: false,
                            },
                        },
                        talk: {
                            maximum_numbers: 100,
                            maximum_sequences: 0,
                            features: {
                                reports: true,
                                speech_to_text: false,
                                record: false,
                                transcribe: false,
                                dial_lists: false,
                                dial_lists_manage: false,
                            },
                        },
                        text: {
                            maximum_numbers: 100,
                            maximum_sequences: 0,
                            features: {
                                reports: true,
                                mms_inbound: false,
                                mms_outbound: false,
                                person_lookup: false,
                                import_metadata: false,
                                import_request: false,
                                import_full: false,
                            },
                        },
                        lookup: {
                            features: {
                                rep_lookup_federal: false,
                                rep_lookup_state: false,
                            },
                        },
                        connect: {
                            services: {
                                actblue: false,
                                actionnetwork: false,
                            },
                        },
                        services: {
                            twilio: {
                                status: null,
                                account_sid: null,
                            },
                        },
                    },
                },
                errors: [],
                clients: [],
                domains: [],
            }
        },
        methods: {
            fetchClient() {
                this.$http.get(`/api/v1/admin/clients/${this.id}`).then(this.refreshClient);
            },
            refreshClient({data}) {
                this.client = data.data;
            },
            fetchDomains() {
                this.$http.get(`/api/v1/admin/domains`).then(({data}) => {
                    this.domains = data.data;
                });
            },
            save() {
                this.$http.put('/api/v1/admin/clients/'+this.id, this.client).then(response => {
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The client has been updated',
                    duration: 5000,
                    speed: 1000
                });

                this.$router.push({name: 'list_clients'});
              }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors || {};
                }
              });
            },
            cancel() {
                this.$router.back();
            },
        }
    }
</script>
