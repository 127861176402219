<template>
    <th v-if="type === 'text'" scope="col" class="py-4 px-6 font-bold uppercase text-sm" v-bind:class="{'tracking-wider': !last, 'relative, text-right': last, 'hidden md:table-cell': hideSm}">
        <slot></slot>
    </th>
    <th v-else scope="col" class="cursor-pointer py-4 px-6 font-bold uppercase text-sm" v-bind:class="{'tracking-wider': !last, 'relative, text-right': last, 'hidden md:table-cell': hideSm}">
        <a class="text-black hover:text-sbr-orange" @click="$emit('click')">
            <slot></slot>
        </a>
    </th>
</template>

<script>

    export default {
        name: 'table-header',
        emits: [
            'click',
        ],
        props: {
            type: String,
            last: Boolean,
            hideSm: Boolean,
        },
    };
</script>
