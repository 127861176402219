import Vuex from 'vuex';
import axios from 'axios';

export default new Vuex.Store({
    state: {
        status: '',
        token: window.$cookies.get('token') || '', //localStorage.getItem('token') || '',
        user: localStorage.getItem('user') || {},
        is_impersonated: ((localStorage.getItem('is_impersonated') === 'false' || localStorage.getItem('is_impersonated') === null) ? false : true),
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, payload) {
            state.status = 'success'
            state.token = payload.token
            state.user = payload.user

            var d = new Date();
            d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));
            let expires = 'expires=' + d.toUTCString()
            let domain = 'domain=' + process.env.MIX_ROOT_APP_URL;
            document.cookie = 'token=' + payload.token + ';' + expires + ';' + domain + ';path=/'

            localStorage.setItem('token', payload.token)
            localStorage.setItem('user', payload.user)
        },
        auth_error(state) {
            state.status = 'error'
        },
        impersonate_success(state, payload) {
            state.status = 'success'
            state.user = payload.user
            state.is_impersonated = true

            localStorage.setItem('user', payload.user)
            localStorage.setItem('is_impersonated', true)
        },
        impersonate_error(state) {
            state.status = 'error'
        },
        release_success(state, payload) {
            state.status = 'success'
            state.user = payload.user
            state.is_impersonated = false

            localStorage.setItem('user', payload.user)
            localStorage.setItem('is_impersonated', false)
        },
        release_error(state) {
            state.status = 'error'
        },
        logout(state) {
            state.status = ''
            state.token = ''
            state.user = ''
            state.is_impersonated = false

            localStorage.removeItem('token')
            localStorage.removeItem('user')
            localStorage.removeItem('is_impersonated')
        },
    },
    actions: {
        login({commit}, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({url: '/api/v1/auth/login', data: user, method: 'POST'})
                    .then(resp => {
                        const token = resp.data.access_token
                        const user = resp.data.user

                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
                        commit('auth_success', {
                            token: token,
                            user: JSON.stringify(user),
                        })
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('auth_error')
                        localStorage.removeItem('token')
                        reject(err)
                    })
            })
        },
        logout({commit}) {
            return new Promise((resolve, reject) => {
                commit('logout')
                delete axios.defaults.headers.common['Authorization']
                window.$cookies.remove('token', '/', process.env.MIX_ROOT_APP_URL)
                resolve()
            })
        },
        verified({commit}, data) {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token
                commit('auth_success', {token: data.token, user: JSON.stringify(data.user)})
                resolve()
            });
        },
        impersonate({commit}, id) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({url: '/api/v1/auth/impersonate', data: id, method: 'POST'})
                    .then(resp => {
                        const user = resp.data.user
                        commit('impersonate_success', {user: JSON.stringify(user)})
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('impersonate_error')
                        reject(err)
                    })
            })
        },
        release({commit}) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({url: '/api/v1/auth/release', data: {}, method: 'POST'})
                    .then(resp => {
                        const user = resp.data.user
                        commit('release_success', {user: JSON.stringify(user)})
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('release_error')
                        reject(err)
                    })
            })
        },
    },
    getters: {
        isLoggedIn: state => !!state.token,
        isImpersonated: state => state.is_impersonated,
        authStatus: state => state.status,
        user: state => {
            return JSON.parse(state.user)
        },
    }
})
