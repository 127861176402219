<template>
    <router-link
        class="mr-5 bg-white text-black font-bold border-red-500 border-2 py-1 px-8 rounded-full hover:bg-sbr-dark-orange hover:bg-opacity-50 hover:text-black transition duration-500 ease-in-out"
        :to="href"
        @click="$emit('click')"
    >
        <slot></slot>
    </router-link>
</template>

<script>

    export default {
        name: 'layout-button',
        emits: [
            'click',
        ],
        props: {
            href: String,
            title: String,
            target: String,
            type: String,
            tag: String,
        },
    };
</script>
