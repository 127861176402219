<template>
    <div>
        <layout-header title="Contact Support"></layout-header>

        <form-section title="">

            <form-field>
                <form-select id="application" name="application" label="Application" v-model="support.application">
                    <option value="Portal">Portal</option>
                    <option value="Chat">Chat</option>
                    <option value="Link">Link</option>
                    <option value="Text">Text</option>
                    <option value="Talk">Talk</option>
                    <option value="None">None</option>
                </form-select>
                <p class="text-red-500 text-xs italic" v-if="errors && errors.application">{{ errors.application[0] }}</p>
            </form-field>

            <form-field>

            </form-field>

            <form-field wide>
                <form-textarea id="message" name="message" label="Message" v-model="support.message"></form-textarea>
                <p class="text-red-500 text-xs italic" v-if="errors && errors.message">{{ errors.message[0] }}</p>
            </form-field>
        </form-section>

        <form-action>
            <form-button @click="save">
                Send Message
            </form-button>

            <form-button secondary @click="cancel">
                Cancel
            </form-button>
        </form-action>
    </div>
</template>

<script>
    import LayoutApp from '../../layouts/App'
    import LayoutHeader from '../../shared/layout/Header'
    import FormSection from '../../shared/form/Section'
    import FormAction from '../../shared/form/Action'
    import FormField from '../../shared/form/Field'
    import FormTextarea from '../../shared/form/TextArea'
    import FormSelect from '../../shared/form/Select'
    import FormButton from '../../shared/form/Button'
    import ValidationNotice from '../../shared/form/ValidationNotice'

    export default {
        name: 'SupportContact',
        components: {
            LayoutApp,
            LayoutHeader,
            FormAction,
            FormSection,
            FormField,
            FormTextarea,
            FormSelect,
            FormButton,
            ValidationNotice,
        },
        created() {
            this.$emit('update:layout', LayoutApp);
        },
        data() {
            return {
                support: {
                    application: 'Portal',
                    message: '',
                },
                errors: []
            }
        },
        methods: {
            save() {
                this.$http.put('/api/v1/support', this.support).then(response => {
                    this.$notify({
                        group: 'alerts',
                        type: 'success',
                        title: 'Your message has been sent',
                        duration: 5000,
                        speed: 1000
                    });
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {};
                    }
                });
            },
            cancel() {
                this.$router.back();
            },
        }
    }
</script>
