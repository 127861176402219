<template>
    <div>
        <div class="flex">
            <h1 class="flex-1 ml-5 font-bold text-lg text-header">Welcome!</h1>
        </div>

        <main class="py-4">
            <div class="px-4">
                <div class="block flex flex-row md:-mx-2">

                    <div class="w-1/4 md:mx-2 mb-4 md:mb-0">
                        <div class="bg-white rounded-lg overflow-hidden shadow relative">
                            <a v-on:click="openApp('chat')" href="javascript:void(0)">
                                <img class="h-84 w-full object-contain object-center py-8 mt-8" src="/img/product-chat.png" alt="" style="height: 175px;">
                                <div class="p-4 pt-16 h-48">
                                    <p class="block text-blue-500 hover:text-blue-600 font-semibold mb-2 text-lg md:text-base lg:text-lg">Chat</p>
                                    <div class="text-gray-600 text-sm leading-relaxed block md:text-xs lg:text-sm">
                                        Create interactive chatbots using the Facebook Messenger platform.
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="w-1/4 md:mx-2 mb-4 md:mb-0">
                        <div class="bg-white rounded-lg overflow-hidden shadow relative">
                            <a v-on:click="openApp('links')" href="javascript:void(0)">
                                <img class="h-84 w-full object-contain object-center py-8 mt-8" src="/img/product-link.png" alt="" style="height: 175px;">
                                <div class="p-4 pt-16 h-48">
                                    <p class="block text-blue-500 hover:text-blue-600 font-semibold mb-2 text-lg md:text-base lg:text-lg">Link</p>
                                    <div class="text-gray-600 text-sm leading-relaxed block md:text-xs lg:text-sm">
                                        Generate short links using custom or supplied short URLs.
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="w-1/4 md:mx-2 mb-4 md:mb-0">
                        <div class="bg-white rounded-lg overflow-hidden shadow relative">
                            <a v-on:click="openApp('text')" href="javascript:void(0)">
                                <img class="h-84 w-full object-contain object-center py-8 mt-8" src="/img/product-text.png" alt="" style="height: 175px;">
                                <div class="p-4 pt-16 h-48">
                                    <p class="block text-blue-500 hover:text-blue-600 font-semibold mb-2 text-lg md:text-base lg:text-lg">Text</p>
                                    <div class="text-gray-600 text-sm leading-relaxed block md:text-xs lg:text-sm">
                                        Engage with users over SMS, MMS, and RCS on mobile devices.
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="w-1/4 md:mx-2 mb-4 md:mb-0">
                        <div class="bg-white rounded-lg overflow-hidden shadow relative">
                            <a v-on:click="openApp('talk')" href="javascript:void(0)">
                                <img class="h-84 w-full object-contain object-center py-8 mt-8" src="/img/product-talk.png" alt="" style="height: 175px;">
                                <div class="p-4 pt-16 h-48">
                                    <p class="block text-blue-500 hover:text-blue-600 font-semibold mb-2 text-lg md:text-base lg:text-lg">Talk</p>
                                    <div class="text-gray-600 text-sm leading-relaxed block md:text-xs lg:text-sm">
                                        Connect users with Click-to-Call actions using interactive voice responses.
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../layouts/App';

    export default {
        name: 'Home',
        components: {
            LayoutApp,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));
        },
        data() {
            return {
                chat_url: process.env.MIX_CHAT_APP_URL,
                links_url: process.env.MIX_LINKS_APP_URL,
                text_url: process.env.MIX_TEXT_APP_URL,
                talk_url: process.env.MIX_TALK_APP_URL,
            }
        },
        computed: {
            user: function() {
                return this.$store.getters.user;
            }
        },
        methods: {
            openApp(app) {
                if (app === 'chat') {
                    if (this.user.permissions.chat) {
                        window.location = this.chat_url;
                    } else {
                        this.block(app);
                    }
                } else if (app === 'links') {
                    if (this.user.permissions.links) {
                        window.location = this.links_url;
                    } else {
                        this.block(app);
                    }
                } else if (app === 'text') {
                    if (this.user.permissions.text) {
                        window.location = this.text_url;
                    } else {
                        this.block(app);
                    }
                } else if (app === 'talk') {
                    if (this.user.permissions.talk) {
                        window.location = this.talk_url;
                    } else {
                        this.block(app);
                    }
                }
            },
            block(app) {
                if (app === 'chat') {
                    this.$modal.show('show-chat');
                } else if (app === 'links') {
                    this.$modal.show('show-links');
                } else if (app === 'text') {
                    this.$modal.show('show-text');
                } else if (app === 'talk') {
                    this.$modal.show('show-talk');
                }
            },
            contact() {
                this.$router.push({name: 'support_contact'})
            }
        }
    }
</script>
