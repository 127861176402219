<template>
    <div class="flex">
        <h1 class="flex-1 ml-5 font-extrabold text-2xl text-header">{{ title }}</h1>
        <slot></slot>
    </div>
</template>

<script>

    export default {
        name: 'layout-header',
        props: {
            title: String,
        },
    };
</script>
