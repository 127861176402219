<template>
  <div class="min-h-screen flex flex-col">
    <!-- Header Navigation -->
    <nav class="bg-sbr-purple">
        <!-- Header (Larger Only) -->
        <div class="max-w-7xl mx-auto px-2">
            <div class="flex items-center justify-between h-16">
                <!-- Logo -->
                <div class="flex items-center pl-2">
                    <div class="flex-shrink-0">
                        <router-link :to="portal_url">
                            <img class="h-8" src="/img/SBR_horiz_med_light.png" />
                        </router-link>
                    </div>
                </div>

                <!-- Right Menu Links (Larger Only) -->
                <div class="hidden md:block">
                    <div class="ml-4 flex items-center">
                        <div class="ml-3 hidden md:block">
                            <div class="ml-10 flex items-baseline space-x-4">
                                <header-link :href="help_url" target="_blank">Help</header-link>
                                <header-link type="router" href="/support/contact">Support</header-link>
                            </div>
                        </div>

                        <!-- Profile dropdown -->
                        <div class="ml-3 relative">
                            <div>
                                <button type="button" @click="toggleUserDropdown()" class="font-semibold p-2 text-gray-200 hover:text-white max-w-xs flex items-center text-sm" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                                    Hi, {{user.first_name}}
                                    <svg class="h-3 fill-current inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                                    </svg>
                                </button>
                            </div>

                            <!--
                                Dropdown menu, show/hide based on menu state.

                                Entering: "transition ease-out duration-100"
                                From: "transform opacity-0 scale-95"
                                To: "transform opacity-100 scale-100"
                                Leaving: "transition ease-in duration-75"
                                From: "transform opacity-100 scale-100"
                                To: "transform opacity-0 scale-95"
                            -->
                            <div id="user_dropdown" class="z-30 origin-top-right absolute right-0 mt-4 w-48 rounded-md shadow-lg bg-white invisible" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
                                <!-- Active: "bg-gray-100", Not Active: "" -->
                                <header-dropdown-link type="router" @click="toggleUserDropdown()" href="/settings/profile"><i class="fa fa-user fa-fw"></i> Profile</header-dropdown-link>
                                <header-dropdown-link type="router" @click="toggleUserDropdown()" href="/settings/account" v-if="current_user.account_level === 'owner' || current_user.account_level === 'admin'"><i class="fa fa-cog fa-fw"></i> Settings</header-dropdown-link>
                                <div class="border border-grey-darkest"></div>
                                <header-dropdown-link @click="releaseUser()" v-if="is_impersonated"><i class="fas fa-sign-out-alt fa-fw"></i> Release User</header-dropdown-link>
                                <header-dropdown-link @click="logoutUser()"><i class="fas fa-sign-out-alt fa-fw"></i> Log Out</header-dropdown-link>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Mobile Menu Button (Mobile Only) -->
                <div class="-mr-2 flex md:hidden">
                    <button type="button" @click="toggleMobileMenu()" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white" aria-controls="mobile-menu" aria-expanded="false">
                        <span class="sr-only">Open main menu</span>
                        <svg id="mobile_menu_icon_closed" class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                        <svg id="mobile_menu_icon_open" class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>

        <!-- Mobile Menu (Mobile Only) -->
        <div class="hidden md:hidden" id="mobile_menu">
            <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
                <header-mobile-link href="/" type="router" @click="toggleMobileMenu()"><i class="mr-2 fas fa-home fa-fw"></i> Home</header-mobile-link>
                <header-mobile-link href="/clients" type="router" @click="toggleMobileMenu()"><i class="mr-2 fas fa-users fa-fw"></i> Clients</header-mobile-link>
                <header-mobile-link href="/users" type="router" @click="toggleMobileMenu()"><i class="mr-2 fas fa-user fa-fw"></i> Users</header-mobile-link>
                <header-mobile-link href="/domains" type="router" v-if="user.is_admin === 1" @click="toggleMobileMenu()"><i class="mr-2 fas fa-globe fa-fw"></i> Domains</header-mobile-link>
                <header-mobile-link href="/api_tokens" type="router" @click="toggleMobileMenu()"><i class="mr-2 fas fa-user-lock fa-fw"></i> API Tokens</header-mobile-link>
            </div>
            <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3 border-t-2 border-white">
                <header-mobile-link :href="help_url" target="_blank" @click="toggleMobileMenu()"><i class="mr-2 fas fa-question-circle fa-fw"></i> Help</header-mobile-link>
                <header-mobile-link href="/support/contact" type="router" @click="toggleMobileMenu()"><i class="mr-2 fas fa-life-ring fa-fw"></i> Support</header-mobile-link>
                <header-mobile-link href="/settings/profile" type="router" @click="toggleMobileMenu()"><i class="mr-2 fa fa-user fa-fw"></i> Profile</header-mobile-link>
                <header-mobile-link href="/settings/account" type="router" @click="toggleMobileMenu()" v-if="current_user.account_level === 'owner' || current_user.account_level === 'admin'"><i class="mr-2 fa fa-cog fa-fw"></i> Settings</header-mobile-link>
            </div>
            <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3 border-t-2 border-white">
                <header-mobile-link @click="releaseUser()" v-if="is_impersonated"><i class="mr-2 fas fa-sign-out-alt fa-fw"></i> Release User</header-mobile-link>
                <header-mobile-link @click="logoutUser()"><i class="mr-2 fas fa-sign-out-alt fa-fw"></i> Log Out</header-mobile-link>
            </div>
        </div>
    </nav>

    <!-- Main Content -->
    <div class="flex flex-col md:flex-row min-h-screen">
        <!-- Left Navigation (Larger Only) -->
        <div class="bg-gray-100 text-black bottom-0 md:relative hidden md:block md:w-48 grid grid-cols-1 divide-y px-2 border-r-2 border-gray-200">
            <div class="py-4">
                <navigation-link href="/" type="router" exact><i class="py-1 mr-2 fas fa-home fa-fw float-right"></i>Home</navigation-link>
                <navigation-link href="/clients" type="router"><i class="py-1 mr-2 fas fa-users fa-fw float-right"></i> Clients</navigation-link>
                <navigation-link href="/users" type="router"><i class="py-1 mr-2 fas fa-user fa-fw float-right"></i> Users</navigation-link>
                <navigation-link href="/domains" type="router" v-if="user.is_admin === 1"><i class="py-1 mr-2 fas fa-globe fa-fw float-right"></i> Domains</navigation-link>
                <navigation-link href="/api_tokens" type="router"><i class="py-1 mr-2 fas fa-user-lock fa-fw float-right"></i> API Tokens</navigation-link>
            </div>

            <div class="pt-4">
                <navigation-link :href="chat_url" v-if="this.user.permissions.chat"><i class="py-1 mr-2 fas fa-robot fa-fw float-right"></i> Chat</navigation-link>
                <navigation-link :href="links_url" v-if="this.user.permissions.links"><i class="py-1 mr-2 fas fa-link fa-fw float-right"></i> Link</navigation-link>
                <navigation-link :href="text_url" v-if="this.user.permissions.text"><i class="py-1 mr-2 fas fa-comment fa-fw float-right"></i> Text</navigation-link>
                <navigation-link :href="talk_url" v-if="this.user.permissions.talk"><i class="py-1 mr-2 fas fa-phone fa-fw float-right"></i> Talk</navigation-link>
            </div>
        </div>

        <!-- Body Content -->
        <div class="main-content flex-1 pt-5 px-4">
            <slot></slot>
            <footer class="text-white pb-4 pt-24">
                <div class="max-w-7xl mx-auto pt-4 px-4 overflow-hidden sm:px-6 lg:px-8">
                    <div class="mt-4 flex justify-center space-x-6 text-lg">
                        <a :href="facebook_url" target="_blank" class="text-gray-600 hover:text-gray-500"><span class="sr-only">Facebook</span> <i class="fab fa-facebook-square"></i></a>
                        <a :href="github_url" target="_blank" class="text-gray-600 hover:text-gray-500"><span class="sr-only">Github</span> <i class="fab fa-github-square"></i></a>
                        <a :href="twitter_url" target="_blank" class="text-gray-600 hover:text-gray-500"><span class="sr-only">Twitter</span> <i class="fab fa-twitter-square"></i></a>
                        <a :href="instagram_url" target="_blank" class="text-gray-600 hover:text-gray-500"><span class="sr-only">Instagram</span> <i class="fab fa-instagram-square"></i></a>
                        <a :href="linkedin_url" target="_blank" class="text-gray-600 hover:text-gray-500"><span class="sr-only">LinkedIn</span> <i class="fab fa-linkedin"></i></a>
                        <a :href="site_url" target="_blank" class="text-gray-600 hover:text-gray-500"><span class="sr-only">Website</span> <i class="fas fa-globe"></i></a>
                    </div>
                    <div class="mt-4 flex justify-center space-x-6">
                        <a :href="privacy_url" target="_blank" class="text-gray-600 hover:text-gray-500">Privacy Policy</a>
                        <!--<a :href="terms_url" target="_blank" class="text-gray-600 hover:text-gray-500">Terms</a>-->
                    </div>
                    <p class="mt-8 text-center text-sm text-gray-600">
                        &copy; {{ current_year }} SeeBotRun, LLC. All rights reserved.
                    </p>
                </div>
            </footer>
        </div>
    </div>

  </div>
</template>

<script>
    import HeaderLink from './header/HeaderLink'
    import HeaderDropdownLink from './header/HeaderDropdownLink'
    import HeaderMobileLink from './header/HeaderMobileLink'
    import NavigationLink from './navigation/NavigationLink'

    export default {
        name: 'layout-app',
        components: {
            HeaderLink,
            HeaderDropdownLink,
            HeaderMobileLink,
            NavigationLink,
        },
        created() {
            this.fetchUser();
        },
        computed: {
            is_impersonated: function() {
                return this.$store.getters.isImpersonated;
            },
            current_user: function() {
                return this.$store.getters.user;
            },
        },
        data() {
            return {
                user: {
                    id: null,
                    first_name: null,
                    last_name: null
                },
                portal_url: process.env.MIX_PORTAL_APP_URL,
                links_url: process.env.MIX_LINKS_APP_URL,
                chat_url: process.env.MIX_CHAT_APP_URL,
                text_url: process.env.MIX_TEXT_APP_URL,
                talk_url: process.env.MIX_TALK_APP_URL,
                help_url: process.env.MIX_HELP_URL,
                support_url: process.env.MIX_SUPPORT_URL,
                facebook_url: process.env.MIX_FACEBOOK_URL,
                twitter_url: process.env.MIX_TWITTER_URL,
                instagram_url: process.env.MIX_INSTAGRAM_URL,
                github_url: process.env.MIX_GITHUB_URL,
                linkedin_url: process.env.MIX_LINKEDIN_URL,
                site_url: process.env.MIX_SITE_URL,
                privacy_url: process.env.MIX_PRIVACY_URL,
                terms_url: process.env.MIX_TERMS_URL,
                current_year: new Date().getFullYear(),
            }
        },
        methods: {
            updateLayout() {
                this.fetchUser();
            },
            fetchUser() {
                this.user = this.$store.getters.user;
            },
            toggleUserDropdown() {
                let id = 'user_dropdown'
                document.getElementById(id).classList.toggle('invisible');
            },
            toggleMobileMenu() {
                let id = 'mobile_menu'
                document.getElementById(id).classList.toggle('hidden')
                document.getElementById(id+'_icon_closed').classList.toggle('hidden')
                document.getElementById(id+'_icon_open').classList.toggle('hidden')
            },
            releaseUser() {
                this.$store.dispatch('release')
                    .then(() => {
                        window.location = '/';
                    })
                    .catch(err => {

                    })
            },
            logoutUser() {
                this.$store.dispatch('logout')
                    .then(() => {
                        window.location = '/login'
                    })
                    .catch(err => console.log(err))
            },
            openApp(app) {
                if (app === 'chat') {
                    window.location = process.env.MIX_CHAT_APP_URL;
                } else if(app === 'links') {
                    window.location = process.env.MIX_LINKS_APP_URL;
                } else if(app === 'text') {
                    window.location = process.env.MIX_TEXT_APP_URL;
                } else if(app === 'talk') {
                    window.location = process.env.MIX_TALK_APP_URL;
                }
            }
        }
    }
</script>
