<template>
    <div class="flex flex-wrap m-5 mt-8 px-3">
        <slot></slot>
    </div>
</template>

<script>

    export default {
        name: 'form-action',
        props: {
        },
    };
</script>
