<template>
    <div class="m-5 bg-form-body border-2 border-sbr-light-purple shadow-md rounded-lg">
        <h2 class="p-4 border-b-2 border-sbr-light-purple font-semibold" v-if="title != ''">{{ title }}</h2>
        <div class="my-2 p-4">
            <div class="w-full grid grid-cols-6 gap-6">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'form-section',
        props: {
            title: String,
        },
    };
</script>
