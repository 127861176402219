import { createRouter, createWebHistory } from 'vue-router';

import Login from './components/auth/Login';
import Forgot from './components/auth/Forgot';
import Reset from './components/auth/Reset';
import Verify from './components/auth/Verify';

import Home from './components/app/Home';
import NotFound from './components/app/NotFound';

import EditProfile from './components/app/settings/EditProfile';
import EditAccount from './components/app/settings/EditAccount';
import SupportContact from './components/app/support/Contact';

import ListClients from './components/app/clients/ListClients';
import CreateClient from './components/app/clients/CreateClient';
import EditClient from './components/app/clients/EditClient';

import ListUsers from './components/app/users/ListUsers';
import CreateUser from './components/app/users/CreateUser';
import EditUser from './components/app/users/EditUser';

import ListDomains from './components/app/domains/ListDomains';
import CreateDomain from './components/app/domains/CreateDomain';
import EditDomain from './components/app/domains/EditDomain';

import ListAPITokens from './components/app/api_tokens/ListAPITokens';
import CreateAPIToken from './components/app/api_tokens/CreateAPIToken';
import EditAPIToken from './components/app/api_tokens/EditAPIToken';

import PlansChat from './components/app/plans/Chat';
import PlansText from './components/app/plans/Text';
import PlansTalk from './components/app/plans/Talk';
import PlansLink from './components/app/plans/Link';

import store from './store';


const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/:pathMatch(.*)*',
            name: 'not-found',
            component: NotFound
        },
        {
          path: '/login',
          name: 'login',
          component: Login
        },
        {
          path: '/forgot',
          name: 'forgot',
          component: Forgot
        },
        {
          path: '/reset',
          name: 'reset',
          component: Reset
        },
        {
          path: '/verify',
          name: 'verify',
          component: Verify
        },
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
              requiresAuth: true
            }
        },
        {
            path: '/settings/profile',
            name: 'edit_profile',
            component: EditProfile,
            meta: {
              requiresAuth: true
            }
        },
        {
            path: '/settings/account',
            name: 'edit_account',
            component: EditAccount,
            meta: {
              requiresAuth: true
            }
        },
        {
            path: '/support/contact',
            name: 'support_contact',
            component: SupportContact,
            meta: {
              requiresAuth: true
            }
        },
        {
            path: '/clients',
            name: 'list_clients',
            component: ListClients,
            meta: {
              requiresAuth: true
            }
        },
        {
            path: '/clients/create',
            name: 'create_client',
            component: CreateClient,
            meta: {
              requiresAuth: true
            }
        },
        {
            path: '/clients/edit/:id',
            name: 'edit_client',
            component: EditClient,
            props: true,
            meta: {
              requiresAuth: true
            }
        },
        {
            path: '/users',
            name: 'list_users',
            component: ListUsers,
            meta: {
              requiresAuth: true
            }
        },
        {
            path: '/users/create',
            name: 'create_user',
            component: CreateUser,
            meta: {
              requiresAuth: true
            }
        },
        {
            path: '/users/edit/:id',
            name: 'edit_user',
            component: EditUser,
            props: true,
            meta: {
              requiresAuth: true
            }
        },
        {
            path: '/domains',
            name: 'list_domains',
            component: ListDomains,
            meta: {
              requiresAuth: true
            }
        },
        {
            path: '/domains/create',
            name: 'create_domain',
            component: CreateDomain,
            meta: {
              requiresAuth: true
            }
        },
        {
            path: '/domains/edit/:id',
            name: 'edit_domain',
            component: EditDomain,
            props: true,
            meta: {
              requiresAuth: true
            }
        },
        {
            path: '/api_tokens',
            name: 'list_api_tokens',
            component: ListAPITokens,
            meta: {
              requiresAuth: true
            }
        },
        {
            path: '/api_tokens/create',
            name: 'create_api_token',
            component: CreateAPIToken,
            meta: {
              requiresAuth: true
            }
        },
        {
            path: '/api_tokens/edit/:id',
            name: 'edit_api_token',
            component: EditAPIToken,
            props: true,
            meta: {
              requiresAuth: true
            }
        },
        {
            path: '/plans/chat',
            name: 'plans_chat',
            component: PlansChat,
            props: true,
            meta: {
              requiresAuth: true
            }
        },
        {
            path: '/plans/text',
            name: 'plans_text',
            component: PlansText,
            props: true,
            meta: {
              requiresAuth: true
            }
        },
        {
            path: '/plans/talk',
            name: 'plans_talk',
            component: PlansTalk,
            props: true,
            meta: {
              requiresAuth: true
            }
        },
        {
            path: '/plans/link',
            name: 'plans_link',
            component: PlansLink,
            props: true,
            meta: {
              requiresAuth: true
            }
        },
    ]
});

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
      if (store.getters.isLoggedIn) {
        next()
        return
      }

      next('/login')
    } else {
      if (store.getters.isLoggedIn) {
        next('/')
        return
      }

      next()
    }
});

export default router;
