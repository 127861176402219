<template>
    <a
        class="mr-5 bg-white text-black border-2 font-bold py-1 px-8 cursor-pointer rounded-full hover:bg-opacity-50 hover:text-black transition duration-500 ease-in-out"
        :class="{'border-2 border-gray-700 hover:bg-gray-400': secondary, 'border-2 border-sbr-dark-orange hover:bg-sbr-dark-orange': !secondary}"
        :href="href"
        @click="$emit('click')"
    >
        <slot></slot>
    </a>
</template>

<script>

    export default {
        name: 'layout-button',
        emits: [
            'click',
        ],
        props: {
            href: String,
            secondary: Boolean,
        },
    };
</script>
