<template>
    <div>
        <layout-header title="Edit Profile"></layout-header>

        <form-section title="Profile Details">
            <form-field>
                <form-input id="first_name" name="first_name" label="First Name" v-model="profile.first_name"></form-input>
                <p class="text-red-500 text-xs italic" v-if="errors && errors.first_name">{{ errors.first_name[0] }}</p>
            </form-field>
            <form-field>
                <form-input id="last_name" name="last_name" label="Last Name" v-model="profile.last_name"></form-input>
                <p class="text-red-500 text-xs italic" v-if="errors && errors.last_name">{{ errors.last_name[0] }}</p>
            </form-field>

            <form-field>
                <form-input id="email" name="email" label="Email Address" v-model="profile.email"></form-input>
                <p class="text-red-500 text-xs italic" v-if="errors && errors.email">{{ errors.email[0] }}</p>
            </form-field>
            <form-field>

            </form-field>

            <form-field>
                <form-input id="password" name="password" label="Change Password" type="password" v-model="profile.password"></form-input>
                <p class="text-red-500 text-xs italic" v-if="errors && errors.password">{{ errors.password[0] }}</p>
            </form-field>
            <form-field>
                <form-input id="confirm_password" name="confirm_password" type="password" label="Confirm Password" v-model="profile.confirm_password"></form-input>
                <p class="text-red-500 text-xs italic" v-if="errors && errors.confirm_password">{{ errors.confirm_password[0] }}</p>
            </form-field>
        </form-section>

        <form-action>
            <form-button @click="save">
                Update Profile
            </form-button>

            <form-button secondary @click="cancel">
                Cancel
            </form-button>
        </form-action>
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App';
    import LayoutHeader from '../../shared/layout/Header';
    import FormSection from '../../shared/form/Section';
    import FormAction from '../../shared/form/Action';
    import FormField from '../../shared/form/Field';
    import FormInput from '../../shared/form/Input';
    import FormSelect from '../../shared/form/Select';
    import FormButton from '../../shared/form/Button';
    import ValidationNotice from '../../shared/form/ValidationNotice';

    export default {
        name: 'EditProfile',
        components: {
            LayoutApp,
            LayoutHeader,
            FormAction,
            FormSection,
            FormField,
            FormInput,
            FormSelect,
            FormButton,
            ValidationNotice,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));

            this.fetchProfile();
        },
        data() {
            return {
                profile: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    password: '',
                    change_password: ''
                },
                errors: []
            }
        },
        methods: {
            fetchProfile() {
                 this.$http.get(`/api/v1/profile`).then(this.refreshProfile);
            },
            refreshProfile({data}) {
                this.profile = data.data;
            },
            save() {
                this.$http.put('/api/v1/profile', this.profile).then(response => {
                    this.$notify({
                        group: 'alerts',
                        type: 'success',
                        title: 'Your profile has been updated',
                        duration: 5000,
                        speed: 1000
                    });
                    this.errors = [];
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {};
                    }
                });
            },
            cancel() {
                this.$router.back();
            },
        }
    }
</script>
