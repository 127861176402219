<template>
    <div>
        <layout-header title="Create New API Token"></layout-header>

        <form-section title="API Token Details">
            <form-field>
                <form-input id="name" name="name" label="Name" v-model="api_token.name"></form-input>
                <form-validation param="name" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="status" name="status" label="Status" v-model="api_token.status">
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                </form-select>
                <form-validation param="status" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="client_id" name="client_id" label="Client" v-model="api_token.client_id">
                    <option :value="null">- Select Client -</option>
                    <option v-for="client in clients" v-bind:key="client.id" :value="client.id">{{ client.name }}</option>
                </form-select>
                <form-validation param="client_id" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="user_id" name="user_id" label="User" v-model="api_token.user_id">
                    <option :value="null">- Select User -</option>
                    <option v-for="user in users" v-bind:key="user.id" :value="user.id">{{ user.first_name + ' ' + user.last_name }} ({{ user.client_name }})</option>
                </form-select>
                <form-validation param="client_id" :errors="errors" />
            </form-field>

            <form-field wide>
                <form-textarea id="notes" name="notes" label="Notes" v-model="api_token.notes"></form-textarea>
                <form-validation param="notes" :errors="errors" />
            </form-field>
        </form-section>

        <form-action>
            <form-button @click="save">
                Create API Token
            </form-button>

            <form-button secondary @click="cancel">
                Cancel
            </form-button>
        </form-action>
    </div>
</template>

<script>
import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App';
    import LayoutHeader from '../../shared/layout/Header';
    import FormSection from '../../shared/form/Section';
    import FormAction from '../../shared/form/Action';
    import FormField from '../../shared/form/Field';
    import FormInput from '../../shared/form/Input';
    import FormTextarea from '../../shared/form/TextArea';
    import FormSelect from '../../shared/form/Select';
    import FormChecklist from '../../shared/form/Checklist';
    import FormCheckbox from '../../shared/form/Checkbox';
    import FormButton from '../../shared/form/Button';
    import FormValidation from '../../shared/form/ValidationNotice';

    export default {
        name: 'CreateAPIToken',
        components: {
            LayoutApp,
            LayoutHeader,
            FormAction,
            FormSection,
            FormField,
            FormInput,
            FormTextarea,
            FormSelect,
            FormChecklist,
            FormCheckbox,
            FormButton,
            FormValidation,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));

            this.fetchClients();
            this.fetchUsers();
        },
        data() {
            return {
                clients: [],
                users: [],
                api_token: {
                    name: '',
                    notes: '',
                    status: 'active',
                    client_id: null,
                    user_id: null,
                },
                errors: [],
            }
        },
        methods: {
            fetchClients() {
                this.$http.get(`/api/v1/admin/clients?per_page=-1`).then(this.refreshClients);
            },
            refreshClients({ data }) {
                this.clients = data.data;
            },
            fetchUsers() {
                this.$http.get(`/api/v1/admin/users?per_page=-1`).then(this.refreshUsers);
            },
            refreshUsers({ data }) {
                this.users = data.data;
            },
            save() {
                this.$http.post('/api/v1/admin/api_tokens', this.api_token).then(response => {
                    this.$notify({
                        group: 'alerts',
                        type: 'success',
                        title: 'The api_token has been created',
                        duration: 5000,
                        speed: 1000
                    });

                    this.$router.push({name: 'list_api_tokens'});
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {};
                    }
                });
            },
            cancel() {
                this.$router.back();
            }
        }
    }

</script>
