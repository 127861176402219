<template>
    <div>
        <h1 class="pl-5 font-bold text-lg text-header">Whoops!</h1>
        <div class="pl-3 h-screen m-5 bg-white">
            <p>The page you're looking for cannot be found</p>
        </div>
    </div>
</template>

<script>
    export default {}
</script>
