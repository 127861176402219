<template>
    <tr v-if="type === 'header'" class="">
        <slot></slot>
    </tr>
    <tr v-else class="hover:bg-gray-200" v-bind:class="{'bg-gray-100': (index % 2 === 0), '': (index % 2 !== 0)}">
        <slot></slot>
    </tr>
</template>

<script>

    export default {
        name: 'table-row',
        props: {
            type: String,
            index: Number,
        },
    };
</script>
