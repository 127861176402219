<template>
   <div class="flex h-screen justify-center">
    <div class="w-full max-w-sm m-auto">
      <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" method="POST" @submit.prevent="resetpassword">
        <div class="mb-8"><img width="320" height="88" src="/img/SBR_horiz_med.png" /></div>

        <p class="text-red-500 text-xs italic mb-4" v-if="show_response">{{ response }}</p>
        <p class="text-red-500 text-xs italic mb-4" v-if="errors && errors.token">{{ errors.token[0] }}</p>

        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="password">New Password</label>
          <input id="password" name="password" type="password" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" v-model="password" required autocomplete="password" autofocus>
          <p class="text-red-500 text-xs italic" v-if="errors && errors.password">{{ errors.password[0] }}</p>
        </div>

        <div class="mb-8">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="password_confirm">Confirm Password</label>
          <input id="password_confirm" name="password_confirm" type="password" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" v-model="password_confirm" required autocomplete="password_confirm" autofocus>
          <p class="text-red-500 text-xs italic" v-if="errors && errors.password_confirm">{{ errors.password_confirm[0] }}</p>
        </div>

        <div class="flex items-center justify-between">
          <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">Reset Password</button>

          <a class="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="/login">Back to Login</a>
        </div>
      </form>

      <p class="text-center text-gray-500 text-xs">
        &copy;2022 All rights reserved.
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Reset',
    data() {
      return {
        token: this.$route.query.token || '',
        password: '',
        password_confirm: '',
        show_response: false,
        response: '',
        errors: {}
      }
    },
    methods: {
      resetpassword: function () {
          this.show_response = false;

          this.$http.post('/api/v1/auth/reset', {token: this.token, password: this.password, password_confirm: this.password_confirm}).then(response => {
              this.response = 'Your password has been reset!';
              this.show_response = true;
              this.password = '';
              this.password_confirm = '';
              this.errors = {};
          }).catch(error => {
              if (error.response.status === 422) {
                  this.errors = error.response.data.errors || {};
              } else {
                  this.errors = {};
                  this.show_response = true;
                  this.response = error.response.data.error || 'An error has occurred';
              }
          });
      }
    }
  }
</script>
