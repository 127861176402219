<template>
    <div>
        <layout-header title="Edit Domain"></layout-header>

        <form-section title="Domain Details">
            <form-field>
                <form-input id="domain" name="domain" label="Name" v-model="domain.name"></form-input>
                <form-validation param="name" :errors="errors" />
            </form-field>

            <form-field>
                <form-select id="status" name="status" label="Status" v-model="domain.status">
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                </form-select>
                <form-validation param="status" :errors="errors" />
            </form-field>

            <form-field>
                <form-input id="url" name="url" label="Domain" v-model="domain.url" placeholder="e.g. seebotrun.com"></form-input>
                <form-validation param="url" :errors="errors" />
            </form-field>
        </form-section>

        <form-action>
            <form-button @click="save">
                Update Domain
            </form-button>

            <form-button secondary @click="cancel">
                Cancel
            </form-button>
        </form-action>
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import LayoutApp from '../../layouts/App';
    import LayoutHeader from '../../shared/layout/Header';
    import FormSection from '../../shared/form/Section';
    import FormAction from '../../shared/form/Action';
    import FormField from '../../shared/form/Field';
    import FormInput from '../../shared/form/Input';
    import FormSelect from '../../shared/form/Select';
    import FormChecklist from '../../shared/form/Checklist';
    import FormCheckbox from '../../shared/form/Checkbox';
    import FormButton from '../../shared/form/Button';
    import FormValidation from '../../shared/form/ValidationNotice';

    export default {
        name: 'EditDomain',
        components: {
            LayoutApp,
            LayoutHeader,
            FormAction,
            FormSection,
            FormField,
            FormInput,
            FormSelect,
            FormChecklist,
            FormCheckbox,
            FormButton,
            FormValidation,
        },
        props: {
            layout: [Object, String],
        },
        emits: [
            'update:layout',
        ],
        created() {
            this.$emit('update:layout', shallowRef(LayoutApp));

            this.fetchDomain();
        },
        computed: {
            id () {
                return this.$route.params.id;
            }
        },
        data() {
            return {
                domain: {
                  id: '',
                  name: '',
                  url: '',
                  status: 'active',
                },
                errors: [],
            }
        },
        methods: {
            fetchDomain() {
                this.$http.get(`/api/v1/admin/domains/${this.id}`).then(this.refreshDomain);
            },
            refreshDomain({data}) {
                this.domain = data.data;
            },
            save() {
                this.$http.put('/api/v1/admin/domains/'+this.id, this.domain).then(response => {
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The domain has been updated',
                    duration: 5000,
                    speed: 1000
                });

                this.$router.push({name: 'list_domains'});
              }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors || {};
                }
              });
            },
            cancel() {
                this.$router.back();
            },
        }
    }
</script>
