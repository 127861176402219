<template>
   <div class="flex h-screen justify-center">
    <div class="w-full max-w-sm m-auto">
      <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" method="POST" @submit.prevent="resetpassword">
        <div class="mb-8"><img width="320" height="88" src="/img/SBR_horiz_med.png" /></div>

        <p class="text-red-500 text-xs italic mb-4" v-if="show_response">{{ response }}</p>

        <div class="mb-8">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="email">Email Address</label>
          <input id="email" name="email" type="email" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring" v-model="email" required autocomplete="email" autofocus>
          <p class="text-red-500 text-xs italic" v-if="errors && errors.email">{{ errors.email[0] }}</p>
        </div>

        <div class="flex items-center justify-between">
          <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring" type="submit">Reset Password</button>

          <a class="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="/login">Back to Login</a>
        </div>
      </form>

      <p class="text-center text-gray-500 text-xs">
        &copy;2022 All rights reserved.
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Forgot',
    data() {
      return {
        email: '',
        show_response: false,
        response: '',
        errors: {},
      }
    },
    methods: {
      resetpassword: function () {
          this.show_response = false;

          this.$http.post('/api/v1/auth/forgot', {email: this.email}).then(response => {
              this.response = 'An email has been sent that will allow you to reset your password.';
              this.show_response = true;
          }).catch(error => {
              if (error.response.status === 422) {
                  this.errors = err.response.data.errors || {};
              } else {
                this.errors = {};
                this.show_response = true;
                this.response = err.response.data.error || 'An error has occurred';
              }
          });
      }
    }
  }
</script>
