<template>
    <div>
        <form-label :id="id" :label="label"></form-label><br />
        <div :class="'w-full grid grid-cols-'+cols+' md:grid-cols-'+mdcols+'  gap-2 pt-1'">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import FormLabel from './Label'

    export default {
        name: 'form-checklist',
        components: {
            FormLabel,
        },
        props: {
            id: String,
            label: String,
            cols: {
                type: [Number, String],
                default: 2,
            },
            mdcols: {
                type: [Number, String],
                default: 2,
            },
        },
    }
</script>
